class Commons {

  getDefaults() {
    var defs = JSON.parse(localStorage.getItem("defaults"));
    return defs == undefined ? {} : defs;
  }

  formatNumber(number) {
    return new Intl.NumberFormat('el-GR', {
      minimumFractionDigits: 2,
    }).format(parseFloat(number.toString()).toFixed(2));
  }

  formatNumberEuro(number) {
    return new Intl.NumberFormat('el-GR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(parseFloat(number.toString()).toFixed(2));
  }

  setDefault(key, value) {
    var defaults = this.getDefaults();
    defaults[key] = value;
    localStorage.setItem("defaults", JSON.stringify(defaults));
  }

  isOnGroup(myDataInvoiceType, group) {
    var selfpricing_invoicearr = ["invoice1_1", "invoice1_4", "invoice1_5", "invoice1_6", "invoice2_1", "invoice2_4", "invoice5_1", "invoice5_2"]
    var customer_forbidden_arr = ["invoice11_1", "invoice11_2", "invoice11_02", "invoice11_3", "invoice11_4", "invoice17_1", "invoice17_5"]
    var isExpense_arr = ["invoice3_1", "invoice13_1", "invoice13_2", "invoice13_3", "invoice16_1", "invoice17_1", "invoice17_5"]
    var countryDisable_arr = ["invoice1_2", "invoice2_2", "invoice1_3", "invoice2_3"];
    var noCountItems_arr = ["invoice2_1", "invoice2_2", "invoice2_3", "invoice2_4", "invoice11_2", "invoice17_1"];
    var noMeasurementUnit_arr = ["invoice2_1", "invoice2_01", "invoice11_02", "invoice2_2", "invoice2_4", "invoice8_1", "invoice8_4", "invoice17_1", "invoice17_5"];
    var noQuantity_arr = ["invoice2_2", "invoice2_4", "invoice8_1", "invoice8_4", "invoice17_1", "invoice17_5"];
    var noVat_arr = ["invoice8_1", "invoice3_1", "invoice8_4", "invoice17_1", "invoice9_3"];
    var zeroVat_arr = ["invoice1_3", "invoice1_2"];
    var trafficDataForbidden_arr = ["invoice2_1", "invoice2_01", "invoice11_2", "invoice11_02"];
    var zeroPrice_arr = ["invoice9_3"];
    var isDeliveryNote_arr = ["invoice1_1","invoice1_2","invoice1_3","invoice1_4","invoice3_1","invoice3_2","invoice5_2","invoice11_1","invoice11_4","invoice11_5","invoice14_1","invoice14_2"]


    switch (group) {
      case "zeroPrice": {
        return zeroPrice_arr.includes(myDataInvoiceType);
      }
      case "zeroVat": {
        return zeroVat_arr.includes(myDataInvoiceType);
      }
      case "trafficDataForbidden": {
        return trafficDataForbidden_arr.includes(myDataInvoiceType);
      }
      case "allowSelfPrice": {
        return selfpricing_invoicearr.includes(myDataInvoiceType);
      }
      case "customerForbidden": {
        return customer_forbidden_arr.includes(myDataInvoiceType);
      }
      case "isExpense": {
        return isExpense_arr.includes(myDataInvoiceType);
      }
      case "countryDisable": {
        return countryDisable_arr.includes(myDataInvoiceType);
      }
      case "noCountItems": {
        return noCountItems_arr.includes(myDataInvoiceType);
      }
      case "noMeasurementUnit": {
        return noMeasurementUnit_arr.includes(myDataInvoiceType);
      }
      case "noQuantity": {
        return noQuantity_arr.includes(myDataInvoiceType);
      }
      case "noVat": {
        return noVat_arr.includes(myDataInvoiceType);
      }
      case "isDeliveryNote":{
        return isDeliveryNote_arr.includes(myDataInvoiceType);
      }
      default: {
        return false;
      }
    }
  }
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  getParentQueryString() {
    var _parent = window.getElementById("targetFrame");
    return window.getElementById("targetFrame").value();
  }
  getInvoiceTypeName(invtype) {
    var invtype_name="invoice"+invtype.key.replace(".","_");
    return invtype_name;
  }

  getPaymentTypeUid(pt){
    switch(pt){
      case "CASH": {
        return 3
      }
      case "WEB_BANKING": {
        return 6
      }
      case "POS": {
        return 7
      }
      case "ON_CREDIT": {
        return 5
      }
      case "DOMESTIC_PAYMENTS_ACCOUNT_NUMBER": {
        return 1
      }
      case "FOREIGN_PAYMENTS_ACCOUNT_NUMBER": {
        return 2
      }
      case "CHECK": {
        return 4
      }
    }
    return;
  }
  
  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }
  convertData(data) {
    var invoiceDetails = [];
    var counter = 0;
    data.saleEntries.forEach(e => {
        counter++;
        var classifications=[];
        e.saleEntryClassifications.forEach(c=>{
            classifications.push({
                classificationCategory:c.classificationCategory,
                classificationType:c.classificationValue
            })
        });
        invoiceDetails.push({
            lineNumber:counter,
            netValue:e.priceWithoutVat,
            vatAmount:e.priceWithVat-e.priceWithoutVat,
            measurementUnit:e.measurementUnit,
            incomeClassification:classifications
        });
    })

    return {
        invoice:[
            {
                uid:data.invoiceUid,
                mark:data.mark,
                issuer:{
                    vatNumber:data.company.taxpayerId,
                    country:data.issuerCountry
                },
                counterpart:{
                    vatNumber:data.customerTaxPayerId,
                    country:data.customerCountry?data.customerCountry.alpha2:""
                },
                invoiceHeader:{
                    invoiceType:data.myDataInvoiceType.replace("invoice",""),
                    series:data.series,
                    aa:data.invoiceNumber
                },
                paymentMethods:{
                    paymentMethodDetails:[{
                        type: this.getPaymentTypeUid(data.paymentType),
                        amount: data.totalPayment
                    }]
                },
                invoiceDetails:invoiceDetails
            }
        ]
    } 
}

  // parseError(error) {
  //   var str = i18n.t(error.code, { lng: "gb" });
  //   var _arr1 = str.split(" ")
  //   var _str = error.message;
  //   var _arr2 = _str.split(" ");
  //   let wildvals = _arr2.filter(x => !_arr1.includes(x));
  //   let wildchar = _arr1.filter(x => !_arr2.includes(x));

  //   console.log(wildvals)
  //   console.log(wildchar)


  //   var _error;
  //   if (wildchar.length <= 1) {
  //     var _d = wildvals.join(' ').toString();
  //     _error = t(error.code).replace(wildchar[0], t(_d));
  //   }
  //   else {
  //     var strArr = [];
  //     wildchar.forEach(e => {
  //       strArr.push(_str.split(e))
  //     })
  //     _error = t(error.code).replace("{Field}", t(_d));
  //   }

  // }
}

export default new Commons();
